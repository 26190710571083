// This is the services component in
// the React application

import './Service.css';
import Trail from '../helper/trail';
import HeroBlock from './Hero';
import { useState } from "react";

import prototypeIcon from './assets/prototype.png';
import warehouseIcon from './assets/warehouse.png';
import apiIcon from './assets/api.png';
import webProgrammingIcon from './assets/web-programming.png';
import dashboardIcon from './assets/dashboard.png';
import salesIcon from './assets/sales.png';

function DataServices() {
    const [open] = useState(true)
    return (
        <>
            <div className="data-services">
                <div className="border"></div>
                <div className="services-trail">
                    <Trail open={open}>
                        <HeroBlock 
                            title="Solutions Architecture" 
                            description="
                                Design and development of technology platforms, hardware architecture, software architecture, network architecture
                                for enterprise business clients.
                                "
                            detail="Design and develop technology platforms, hardware and software architecture, and network architecture for enterprise clients. Collaborate closely with clients to identify requirements and design tailored solutions. Create scalable, secure, and high-performance architectures aligned with business objectives. Services include system design, infrastructure planning, application integration, and scalability planning."
                            iconSrc={prototypeIcon}
                            // <a href="https://www.flaticon.com/free-icons/prototype" title="prototype icons">Prototype icons created by wanicon - Flaticon</a>
                        />
                        <div className="border"></div>
                        <HeroBlock 
                            title="Data Warehousing" 
                            description="
                                Implementation of high-performance data warehousing solutions to consolidate disparate data sources for analytics and reporting.
                                "
                            detail="Implement high-performance data warehousing solutions to consolidate disparate data sources for analytics and reporting. Organize, integrate, and make data easily accessible for data-driven decision-making. Prioritize data quality, integrity, and security for reliable insights. Streamline data processes, reduce silos, and gain a holistic view of business performance."
                            iconSrc={warehouseIcon}
                            // <a href="https://www.flaticon.com/free-icons/api" title="api icons">Api icons created by Becris - Flaticon</a>
                        />
                        <div className="border"></div>
                        <HeroBlock 
                            title="API Integrations" 
                            description="
                                Implementation of batch and real-time APIs to synchronize data between applications and servers.
                                "
                            detail="Implement batch and real-time APIs to synchronize data between applications and servers. Streamline data transfer, ensure data consistency, and improve process automation. Develop robust and scalable integrations tailored to business needs. Enhance data accessibility, optimize workflows, and improve connectivity between systems."
                            iconSrc={apiIcon}
                            // <a href="https://www.flaticon.com/free-icons/settings" title="settings icons">Settings icons created by srip - Flaticon</a>
                        />
                        <div className="border"></div>
                        <HeroBlock 
                            title="Web Application Development" 
                            description="
                                Full-stack development using JavaScript or Python for business-critical web applications.
                                "
                            detail="Full-stack development using JavaScript or Python for business-critical web applications. Understand unique business requirements and translate them into scalable and robust applications. Follow industry best practices and leverage the latest technologies. Deliver user-friendly, secure, and performant web experiences. Support long-term growth and provide engaging user interfaces."
                            iconSrc={webProgrammingIcon}
                            // <a href="https://www.flaticon.com/free-icons/code" title="code icons">Code icons created by Icongeek26 - Flaticon</a>
                        />
                        <div className="border"></div>
                        <HeroBlock 
                            title="Dashboards and Reports" 
                            description="
                                BI reports and dashboards using the client's preferred visualization software, if not Microsoft Power BI.
                                "
                            detail="Create powerful and insightful BI dashboards and reports using preferred visualization software. Transform raw data into visually appealing and interactive dashboards. Provide valuable insights into business performance. Tailor dashboards to specific metrics, KPIs, and reporting needs. Optimize usability, data storytelling, and maximize impact. Provide ongoing support and maintenance for accurate and up-to-date insights."
                            iconSrc={dashboardIcon}
                            // <a href="https://www.flaticon.com/free-icons/dashboard" title="dashboard icons">Dashboard icons created by Andrean Prabowo - Flaticon</a>
                        />
                        <div className="border"></div>
                        <HeroBlock 
                            title="Data Trend Forecasting" 
                            description="
                                Making predictions about the future using historical data.
                                "
                            detail="Make predictions about the future using historical data. Leverage advanced statistical models and machine learning techniques. Uncover hidden patterns, identify trends, and provide accurate forecasts. Anticipate market trends, optimize inventory management, and develop effective marketing strategies. Tailor the forecasting process to specific business requirements. Deliver reliable forecasts for strategic decision-making. Offer comprehensive reports and visualizations for understanding potential implications."
                            iconSrc={salesIcon}
                            // <a href="https://www.flaticon.com/free-icons/forecast" title="forecast icons">Forecast icons created by Freepik - Flaticon</a>
                        />
                    </Trail>
                </div>
            </div>
        </>
    )
}

export default DataServices;
