import React from 'react';
import { Link } from "react-router-dom";

// handle click

const handleClickHeader = () => {
  // Navigate to the Contact form
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

class FlexContainer extends React.Component {
    render() {
      return(
              <div className="flexContainer" style={{  
                  display: 'flex',
                }}
                >
                  {this.props.children}
                </div>
      )
    }
  };
  
class FlexLink extends React.Component{
    render() {
      return(
    
          <div className="flexLink" style={{ 
              display: 'flex',
              flex: 1}}
              onClick={handleClickHeader}
            >
              <Link className="link" style={{color:'black'}} to={this.props.route}>{this.props.name}</Link>
            </div>
      )
    }
  };

export { FlexContainer, FlexLink }