import React from 'react';
import FilterGroup from '../../helper/filterGroup';
import './HeroBlock.css';

class HeroBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedTicket: false,
            open: false,
        };
        this.toggleExpandedTicket = this.toggleExpandedTicket.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    toggleExpandedTicket() {
        this.setState((prevState) => ({
            expandedTicket: !prevState.expandedTicket
        }));
    }

    handleClickOutside(event) {
        if (
            this.state.expandedTicket &&
            this.expandedTicketRef &&
            !this.expandedTicketRef.contains(event.target)
        ) {
            this.toggleExpandedTicket();
        }
    }

    setExpandedTicketRef = (node) => {
        this.expandedTicketRef = node;
    };

    render() {
        return (
            <>
                <div className="heroBlock">
                    <div className="heroIcon">
                        <img className="heroIconImg" src={this.props.iconSrc ? this.props.iconSrc : ''} alt="solutions-architecture-icon" title="prototype icons" />
                    </div>
                    <div className="heroStatement">
                        {this.props.title}
                    </div>
                    <div className="heroDescription">
                        {this.props.description}
                    </div>
                </div>
                {/* <div onClick={this.toggleExpandedTicket} ref={this.setExpandedTicketRef}>
                    <FilterGroup />
                </div> */}
                {/* <div
                    style={{
                        height: this.state.expandedTicket ? 'auto' : 0,
                        marginBottom: this.state.expandedTicket ? '10px' : 0,
                        opacity: this.state.expandedTicket ? 1 : 0,
                        color: this.state.expandedTicket ? 'black' : 'white'
                    }}
                    className="heroDetail"
                >
                    {this.props.detail}
                </div> */}
            </>
        );
    }
}

export default HeroBlock;
