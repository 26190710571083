// This is the services component in
// the React application

import React from 'react';
import './Service.css';
import DataService from './DataService.js';
import ESGService from './ESGService.js';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

class Service extends React.Component {
  constructor(props) {
      super(props);
      this.myRef = React.createRef()
      const local = localStorage.getItem('state');
      if (local) {
        this.state = JSON.parse(local);
      } else {
          this.state = {
            site_type: 'esg',
        };
      }
      this.handleClick = this.handleClick.bind(this);
  };

  componentDidMount() {
    this.myRef.current.scrollTo(0, 0);
  }

  handleClick(event, value) {
    if (value !== null) {
      this.setState({site_type: value})
      localStorage.setItem('state', JSON.stringify({site_type: value}));
    }
  };

  getService(props) {
    const site_type = props.site_type;
    if (site_type === 'data') {
        return <DataService />
    }
    return <ESGService />
  };

  render() {
    return (
      <div className="Service" ref={this.myRef}>  
        <h1 className="title">SERVICES</h1>
        <ToggleButtonGroup
            color="primary"
            style={{'marginBottom': '25px'}}
            value={this.state.site_type}
            exclusive
            onChange={this.handleClick}>
            <ToggleButton style={{'padding': '5px', 'minWidth': '45px'}} value="esg">ESG</ToggleButton>
            <ToggleButton style={{'padding': '5px', 'minWidth': '45px'}} value="data">Data</ToggleButton>
        </ToggleButtonGroup>
        <this.getService site_type={this.state.site_type} />
      </div>
  )};
}

export default Service;