// This is the about component in
// the React application

import React from 'react';
import './Trending.css'

function Trending() {

  return (
    <div className="Trending">
    </div>
  )
};

export default Trending;