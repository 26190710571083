// This is the services component in
// the React application

import './Service.css';
import Trail from '../helper/trail';
import HeroBlock from './Hero/HeroBlock.js';
import { useState } from "react";

import membraneIcon from './assets/membrane.png';
import benchmarkIcon from './assets/benchmark.png';
import ecoFriendlyIcon from './assets/eco-friendly.png';
import researchIcon from './assets/research.png';
import userIcon from './assets/user.png';
import neuralIcon from './assets/neural.png';
import auditIcon from './assets/audit.png';
import productIcon from './assets/product.png';

function ESGServices() {
  const [open] = useState(true);

  return (
    <>
      <div className="esg-services">
        <div className="border"></div>
        <div className="services-trail">
          <Trail open={open}>
            <HeroBlock 
              title="Materiality Assessments & Sustainability Planning" 
              description="
                Support your core business and all stakeholders with a focus on science-based targets
                and a net-zero emissions goal. Conduct materiality assessments and develop comprehensive sustainability plans to align with global standards and drive positive environmental and social impact."
              detail="Identify key impact areas, set measurable goals, and implement strategies for sustainable growth."
              iconSrc={membraneIcon}
            />
            <div className="border"></div>
            <HeroBlock 
              title="Benchmarking" 
              description="
                Compare ESG performance of business assets across any industry. Systematically evaluate performance towards certifications, voluntary standards,
                and industry benchmarks. Identify strengths, weaknesses, and improvement opportunities. Leverage best practices to advance sustainable initiatives and foster collaboration."
              detail="Gain insights into industry leaders, drive continuous improvement, and align your performance with global sustainability standards."
              iconSrc={benchmarkIcon}
            />
            <div className="border"></div>
            <HeroBlock 
              title="Sustainability Reporting" 
              description="
                Collection, validation, analysis, and reporting of sustainability metrics on a recurring basis using automation. 
                Optimize the way you integrate ESG reporting into the fabric of your business.
                Develop scalable and automated processes that provide a reliable stream of report-ready data
                for your sustainability professionals to work with."
              detail="Ensure accurate and transparent sustainability reporting, streamline data management, and enhance decision-making based on actionable insights."
              iconSrc={ecoFriendlyIcon}
            />
            <div className="border"></div>
            <HeroBlock 
              title="Gap Analysis and Disclosure Recommendations" 
              description="
                Identify areas of opportunity for expanding reporting into new areas
                of the value chain in order to unlock additional certifications 
                increasing access to long-term sustainable business partners."
              detail="Identify gaps in reporting, disclosure, and certification coverage, and receive tailored recommendations to strengthen sustainability practices and stakeholder engagement."
              iconSrc={researchIcon}
            />
            <div className="border"></div>
            <HeroBlock 
              title="Stakeholder Engagement" 
              description="
                Facilitate online surveys, interviews, and polls with stakeholders
                that can be aggregated and deployed to sustainability reporting tools automatically."
              detail="Engage with stakeholders effectively, gather valuable feedback, and strengthen relationships to drive collaborative sustainability initiatives and foster trust."
              iconSrc={userIcon}
            />
            <div className="border"></div>
            <HeroBlock 
              title="Supply Chain Mapping & Risk Assessment" 
              description="
                Visualize geological and contextual maps of your partner, vendor, and supplier networks when working on improving supply chain resilience."
              detail="Identify supply chain risks, dependencies, and vulnerabilities, and develop strategies to mitigate potential disruptions and improve overall supply chain sustainability."
              iconSrc={neuralIcon}
            />
            <div className="border"></div>
            <HeroBlock 
              title="Audit Analytics" 
              description="
                Centralize your audit history and analyze to identify areas of improvement
                to more readily comply with mandatory and voluntary laws, regulations, and certifications
                that influence your business most."
              detail="Leverage data-driven insights from audit analytics to enhance compliance efforts, identify compliance gaps, and strengthen risk management practices."
              iconSrc={auditIcon}
            />
            <div className="border"></div>
            <HeroBlock 
              title="Life Cycle Assessments & Life Cycle Cost Analysis" 
              description="
                Catalog the economic and ESG life cycles of products and processes, assisting with
                identification of sustainability-adjusted initiatives adding value to the business."
              detail="Analyze product and process life cycles, quantify environmental impacts, and assess life cycle costs to drive sustainable innovation, optimize resource use, and enhance competitiveness."
              iconSrc={productIcon}
            />
          </Trail>
        </div>
      </div>
    </>
  )
}

export default ESGServices;
