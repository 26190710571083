// This is the root component in
// the React application

import './App.css';
import React from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import Header from '../Header';
import Product from '../Product';
import Service from '../Services';
import Work from '../Work';
import WorkItemDetail from '../Work';
import About from '../About';
import Landing from '../Landing';
import Trending from '../Trending';
import Contact from '../Contact'


function App() {
    let location = useLocation();
    if (location.pathname === '/') {
        return (
            <div className="App">  
                <Header />
                <div className="primaryContent">
                    <Routes>
                        <Route path="/" element={<Product />}></Route>
                    </Routes>
                </div>
                <div className="secondaryContent">
                    <div className="contactForm">
                        <Contact />
                    </div>
                    <div className="footer">
                        Copyright 2023 © Kimono Global, Inc. All rights reserved.
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="App">  
                <Header />
                <div className="primaryContent">
                    <Routes>
                        <Route path="/about" element={<About />}></Route>
                        {/* <Route path="/services" element={<Service />}></Route> */}
                        <Route path="/product" element={<Product />}></Route>
                        {/* <Route path="/work" element={<Work />}></Route> */}
                        {/* <Route path="/work/:id" component={<WorkItemDetail />}></Route> */}
                        {/* <Route path="/trending" element={<Trending />}></Route> */}
                    </Routes>
                </div>
                <br></br>
                <div className="secondaryContent">
                    <div className="contactForm">
                        <Contact />
                    </div>
                    <div className="footer">
                        Copyright 2023 © Kimono Global, Inc. All rights reserved.
                    </div>
                </div>
            </div>
        )
    }
}

export default App;
