import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseRounded from '@mui/icons-material/CloseRounded'
// web.cjs is required for IE11 support
import { useSpring, animated } from '@react-spring/web';

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: '50%',
  bgcolor: 'background.paper',
  border: '1.5px solid lightgray',
  borderRadius: '1%',
  boxShadow: 24,
  padding: '50px'
};

export default function SpringModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log(props);
  return (
    <div>
      <Button onClick={handleOpen}>View</Button>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: {enter: 500},
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <CloseRounded onClick={handleClose} style={{ cursor: 'pointer', float: 'right' }}/>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              {props.props.title}
            </Typography>
            <Typography id="spring-modal-description" sx={{ mt: 2 }}>
              {props.props.desc}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}