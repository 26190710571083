// This is the about component in
// the React application

import React from 'react';
import './About.css';
import Ticker from 'react-ticker'
import nastyaImage from '../../assets/nastya.png';
import thomasImage from '../../assets/thomas.jpg';
import tickerContent from './tickerContent.json';

function About() {
    return (
      <div className="About"> 
          <h2 className="about-content-header">
            Founder
          </h2>
        <div className="about-profile">
          <div className="about-primary">
            <div className="about-image">
                <img className="profile-pic" alt="nastya.png" src={nastyaImage} />
                <p className="profile-pic-title"><strong>Anastasiia Loskutova</strong></p>
            </div>
            <div className="about-desc">
                <p className="about-desc-para">
                <strong>Anastasiia</strong>, a Corporate Sustainability Professional focused on advising companies through their ESG journey.
                <br></br><br></br><strong>I am a firm believer</strong> that our values matter and our actions can make a difference. Therefore, I am confident that my passion for sustainability, ESG and social responsibility along with my extensive professional and educational background will help me to make a difference in the sustainability compliance and reporting area and make this world a better place as a result through ensuring that private and public companies will embed sustainability in their DNA  and effectively communicate their commitment to corporate responsibility.
                <br></br><br></br><strong>I help companies</strong> create, integrate and manage ESG strategy, goals and reporting. Working as a corporate lawyer, project, sustainability and marketing manager in my previous roles as well as having a law and business degree with emphasis on ESG and sustainability make me understand business on a deep level so I am able to help them achieve their operational and financial goals without compromising environmental compliance and corporate social responsibility.
                <br></br><br></br><strong>I am drawn towards</strong> finding solutions to the complex problems and advancing sustainability initiatives. I help companies bridge the gap between their financial and sustainability reporting through advisory and assurance projects on ESG performance data.
                </p>
            </div>
          </div>
        <br></br><div className="border"></div><br></br>
        <p style={{textAlign: 'left', margin: '0 0 0 125px'}}><strong>SPECIALTIES</strong></p>
          <div className="about-secondary">
            <div className="about-specialties">
              <div className="spec-group">
                <ul>
                  <li>Strategy development and implementation</li>
                  <li>Sustainability program management</li>
                  <li>Materiality assessment and goal setting</li>
                  <li>Sustainability reporting and disclosure (GRI, SASB, UN SDGs, TCFD, CDP)</li>
                </ul>
              </div>
              <div className="spec-group">
                <ul>
                  <li>Sustainable supply chain and procurement strategy development</li>
                  <li>Climate risk assessment and mitigation</li>
                  <li>Benchmarking and research on clients ESG frameworks</li>
                  <li>Stakeholder engagement</li>
                </ul>
              </div>
              <div className="spec-group">
                <ul>
                  <li>ESG rating and ranking analysis</li>
                  <li>Greenhouse emissions accounting</li>
                  <li>Life Cycle Assessment and Life Cycle Cost Analysis</li>
                  <li>Environmental policy and legislation assessments</li>
                </ul>
              </div>
              <div className="spec-group">
                <ul>
                  <li>Measuring Sustainable Impacts</li>
                  <li>Resource Management</li>
                  <li>Project Mangement</li>
                  <li>Business Process Design</li> 
                  <li>Marketing and Communications</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br></br><div className="border"></div><br></br>
        <div className="about-profile">
          <h2 className="about-content-header">
            Co-Founder
          </h2>
          <div className="about-primary">
            <div className="about-image">
                <img className="profile-pic" alt="thomas.jpg" src={thomasImage} />
                <p className="profile-pic-title"><strong>Tom Juntunen</strong></p>
            </div>
            <div className="about-desc">
                <p className="about-desc-para">
                <strong>Tom</strong>, a Data Engineer focused on advising companies on their infrastructure, modeling, and machine learning.
                <br></br><br></br><strong>I am a firm believer</strong> that the decisions we can collectively make using data can drive global progress towards common goals.
                <br></br><br></br><strong>I help companies</strong> design, develop, deploy, monitor, and notify modern data infrastructure, with tools for businesses of any size and complexity.
                <br></br><br></br><strong>I am drawn towards</strong> collective intelligence solutions to social impact challenges. I help companies bridge the gap between their data systems and ESG reporting requirements.
                </p>
            </div>
          </div>
        <br></br><div className="border"></div><br></br>
        <p style={{textAlign: 'left', margin: '0 0 0 125px'}}><strong>SPECIALTIES</strong></p>
          <div className="about-secondary">
            <div className="about-specialties">
              <div className="spec-group">
                <ul>
                  <li>Business Process Analysis</li>
                  <li>Current Infrastructure Analysis</li>
                  <li>New Solution Architecture</li>
                  <li>Testing Environments</li>
                  <li>Deployment, Monitoring, SLA Management</li>
                </ul>
              </div>
              <div className="spec-group">
                <ul>
                  <li>AWS, Azure, GCP</li>
                  <li>SQL, NoSQL, Blob/S3/Bucket</li>
                  <li>Python, Javascript, C#</li>
                  <li>React, Angular, Flask, Django</li>
                  <li>Airflow, Docker, Kubernetes, Kafka</li>
                  <li>PowerBI, Tableau, Airtable, Excel</li>
                </ul>
              </div>
              <div className="spec-group">
                <ul>
                  <li>Data Catalogs</li>
                  <li>Dimensional Modeling</li>
                  <li>Fact Modeling</li>
                  <li>Snapshot, Change History, Point-in-Time Modeling</li>
                  <li>Maintenance and Backup Plans</li>
                </ul>
              </div>
              <div className="spec-group">
                <ul>
                  <li>Full-Stack Web Development</li>
                  <li>API Development</li>
                  <li>Database Administration</li>
                  <li>Managed Cloud Services</li> 
                  <li>Machine Learning and Analytics</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br></br><div className="border"></div><br></br>
        <div className="about-content">
          <div className="about-content-item">
            <h2 className="about-content-header">
              Revolutionize Your ESG Data Management with Our SaaS Solution
            </h2>
            <div className="about-content-body">
              <p>From industries like commercial construction to fashion apparel, our SaaS product empowers businesses to build and centralize their ESG data warehouse, develop dimensional models for point-in-time and historical trend analysis, time-series forecasting, spreadsheet automation, and real-time dashboards. Our platform helps your teams make faster, more consistent decisions while addressing environmental, social, and governance factors.</p>
              <ul>
                <li><strong>Leverage our SaaS solution to lower carbon emissions towards net-zero</strong></li>
                <li><strong>Ensure compliance and avoid unforeseen financial risks and investor scrutiny</strong></li>
                <li><strong>Diversify your supply chain, products, and offerings with new sustainable options</strong></li>
              </ul>
            </div>
          </div>
          <div className="about-content-item">
            <h2 className="about-content-header">
              What Our SaaS Product Offers
            </h2>
            <div className="about-content-body">
              <p>Our dedicated team is committed to supporting your mission and addressing all your ESG and data infrastructure requirements. With our SaaS solution, we streamline your systems, enhance insights gained from reports, and guide your business towards lower costs, higher operational efficiency, and improved margins within a sustainable global community.</p>
              <ul>
                <li><strong>Share your strengths and areas of improvement with us</strong></li>
                <li><strong>Put your trust in us as designers, engineers, and business professionals to achieve your ESG goals</strong></li>
                <li><strong>Adaptability to your specific industry, business, and technology stack, with assistance in upgrading your tools</strong></li>
              </ul>
            </div>
          </div>
          <div className="about-content-item">
            <h2 className="about-content-header">
              Our Differentiation in ESG Data Management
            </h2>
            <div className="about-content-body">
              <p>At the intersection of ESG, Data Infrastructure, and Business Administration, we specialize in providing reliable systems for generating, updating, and analyzing ESG data efficiently. Our SaaS product reduces the time and cost spent on complex ESG data, allowing you to shorten reporting timelines from weeks to hours. Whether you work with another ESG advising agency or not, we can complement the relationship by codifying existing processes and freeing up time for expanding into new sustainable business domains.</p>
              <ul>
                <li><strong>Structure your ESG data for automated reporting</strong></li>
                <li><strong>Shift focus from data input to analyzing insights and developing data-driven proposals</strong></li>
                <li><strong>Avoid recreating the wheel every quarter with our efficient solution</strong></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="border-double"></div>
        <div className='about-ticker'>
        <Ticker speed={15}>
          {({ index }) => (
            <>
              <div className="ticker">
                <p className="quote">"{tickerContent[index % 3].quote}"</p>
                <p className="source">{tickerContent[index % 3].source}</p>
              </div>
            </>
          )}
        </Ticker>
        </div>
      </div>
  )};

export default About;