// This is the hero component in
// the React application

import React from 'react';
import './Work.css';
import WorkItemDetail from './WorkItemDetail';

class WorkItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedTicket: false,
            open: false,
        }
        this.toggleExpandedTicket = this.toggleExpandedTicket.bind(this);
    }
        toggleExpandedTicket() {
            this.setState({ expandedTicket: !this.state.expandedTicket })
        }

        render() {
            return(
                <>
                <div className="col-card" onClick={this.toggleExpandedTicket}>
                <div className="col-card-img">
                    <img className="col-card-ccimg" alt={this.props.title} src={this.props.imgSrc}></img>
                </div>
                <h3 className="col-card-title">{this.props.title}</h3>
                <p className="col-card-desc">
                    {this.props.desc}
                </p>
                <div
                    className="heroDetail">
                        <WorkItemDetail props={this.props}/>
                </div>
                </div>
                
                </>
            )
    }
}

export default WorkItem;