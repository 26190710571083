import React from 'react';
import { useTrail, a } from '@react-spring/web'

const Trail = ({open, children}) => {
    const items = React.Children.toArray(children)
    const trail = useTrail(items.length, {
      config: { mass: 2, tension: 50, friction: 2, clamp: true },
      opacity: open ? 1 : 0,
      height: open ? 'auto' : 0,
      from: { opacity: 0, height: 0 },
    })
    return (
      <div>
        {trail.map(({ height, ...style }, index) => (
          <a.div className="trail-item" key={index} style={style}>
            <a.div style={{ height }}>{items[index]}</a.div>
          </a.div>
        ))}
      </div>
    )
  }

export default Trail;