// This is the header component in
// the React application

import React from "react";
import { useLocation } from 'react-router-dom'
import { FlexContainer, FlexLink } from '../helper/flexContainer';
import { Link } from "react-router-dom";
import './Header.css';
import logoImage from '../../assets/kimono-3.png';

function Header(props) {
    let location = useLocation();
    // if (location.pathname === '/') {
    //     return null;
    // }
        return (
            <>
                <div className="header">
                    <FlexContainer>
                        <div style={{display: 'flex', flex: 1}}>
                            <Link className="LogoLink" to="/product">
                                <img className="logo" src={logoImage}></img>
                            </Link>
                        </div>
                        <FlexLink name="About" route="/about"/>
                        {/* <FlexLink name="Services" route="/services"/> */}
                        <FlexLink name="Product" route="/product"/>
                        {/* <FlexLink name="Work" route="/work"/> */}
                        {/* <FlexLink name="Trending" route="/trending"/> */}
                    </FlexContainer>
                </div>
            </>
        )
}

export default Header;

// style={{'opacity': props.scrolled_to_top ? 1 : 0.5}}