import React from 'react';
import axios from 'axios';
import './Contact.css';
import { Button, Input } from '@mui/material';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: '',
        company: '',
        industry_sector: '',
        email: '',
        message: '',
      },
      max_msg_len: false,
      limit: 1024,
      errors: {
        name: false,
        company: false,
        industry_sector: false,
        email: false,
        message: false,
      },
    };
  }

  handleSubmit(e) {
    e.preventDefault();

    const errors = {};
    let hasErrors = false;

    Object.keys(this.state.form).forEach((key) => {
      if (!this.state.form[key]) {
        errors[key] = true;
        hasErrors = true;
      }
    });

    if (hasErrors) {
      this.setState({ errors });
      return;
    }

    axios({
      method: 'POST',
      url: 'https://kimono.global/api/contact_us',
      data: this.state.form,
    })
      .then((response) => {
        if (response.data.status_code === 200) {
          alert('Message sent successfully!');
          this.resetForm();
        } else {
          alert('Message failed to send.');
        }
      })
      .catch((error) => {
        console.error('Error sending message:', error);
        alert('An error occurred while sending the message. Please try again.');
      });
  }

  resetForm() {
    this.setState({
      form: {
        name: '',
        company: '',
        industry_sector: '',
        email: '',
        message: '',
      },
      errors: {
        name: false,
        company: false,
        industry_sector: false,
        email: false,
        message: false,
      },
    });
  }

  handleInputChange(event, field) {
    const form = { ...this.state.form };
    form[field] = event.target.value;
    this.setState({ form });

    const errors = { ...this.state.errors };
    errors[field] = false;
    this.setState({ errors });
  }

  render() {
    const { form, errors, max_msg_len, limit } = this.state;

    return (
      <div className="Contact">
        <h1 style={{lineHeight: '2.5rem'}}>Book a Demo!</h1>
        <br></br>
        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <Input
              type="text"
              className={errors.name ? 'form-control error' : 'form-control'}
              id="name"
              value={form.name}
              onChange={(e) => this.handleInputChange(e, 'name')}
            />
            {errors.name && <span className="error-message">Please enter your name</span>}
          </div>
          <div className="form-group">
            <label htmlFor="company">Company</label>
            <Input
              type="text"
              className={errors.company ? 'form-control error' : 'form-control'}
              id="company"
              value={form.company}
              onChange={(e) => this.handleInputChange(e, 'company')}
            />
            {errors.company && <span className="error-message">Please enter your company</span>}
          </div>
          <div className="form-group">
            <label htmlFor="industry_sector">Industry or Sector</label>
            <Input
              type="text"
              className={errors.industry_sector ? 'form-control error' : 'form-control'}
              id="industry_sector"
              value={form.industry_sector}
              onChange={(e) => this.handleInputChange(e, 'industry_sector')}
            />
            {errors.industry_sector && <span className="error-message">Please enter your industry or sector</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <Input
              type="email"
              className={errors.email ? 'form-control error' : 'form-control'}
              id="email"
              aria-describedby="emailHelp"
              value={form.email}
              onChange={(e) => this.handleInputChange(e, 'email')}
            />
            {errors.email && <span className="error-message">Please enter a valid email address</span>}
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <Input
              multiline={true}
              className={errors.message ? 'form-control error' : 'form-control'}
              rows="5"
              id="message"
              placeholder="Tell us more about your business and your preferred date and time for the demo. We will get back to you soon to schedule the demo."
              value={form.message}
              onChange={(e) => this.handleInputChange(e, 'message')}
            />
            {errors.message && <span className="error-message">Please enter a message</span>}
            {max_msg_len && <span className="error-message">The limit is {limit} characters.</span>}
          </div>
          <div className="button">
            <Button type="submit" style={{ color: 'lightgray', backgroundColor: '#323438' }} className="btn">
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default Contact;
