// This is the about component in
// the React application

import React from 'react';
import './Work.css';
import WorkItem from './WorkItem.js';

class Work extends React.Component {
  constructor(props) {
    super(props);
    this.items = [
      {
      'id': 0,
      'title': 'Project 1 Title',
      'desc': "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      'src': require("./assets/nastya.png")
    },
    {
      'id': 1,
      'title': 'Project 1 Title',
      'desc': "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      'src': require("./assets/nastya.png")
    },
    {
      'id': 2,
      'title': 'Project 1 Title',
      'desc': "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      'src': require("./assets/nastya.png")
    },
    {
      'id': 3,
      'title': 'Project 1 Title',
      'desc': "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      'src': require("./assets/nastya.png")
    },
    {
      'id': 4,
      'title': 'Project 1 Title',
      'desc': "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      'src': require("./assets/nastya.png")
    },
    {
      'id': 5,
      'title': 'Project 1 Title',
      'desc': "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      'src': require("./assets/nastya.png")
    },
    {
      'id': 6,
      'title': 'Project 1 Title',
      'desc': "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      'src': require("./assets/nastya.png")
    },
    {
      'id': 7,
      'title': 'Project 1 Title',
      'desc': "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      'src': require("./assets/nastya.png")
    },
    {
      'id': 8,
      'title': 'Project 1 Title',
      'desc': "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      'src': require("./assets/nastya.png")
    },

  ]}

  render() {
    const items = [];

    for (let item of this.items) {
      items.push(<WorkItem 
        key={item['id']}
        id={item['id']}
        title={item['title']} 
        desc={item['desc']}
        imgSrc={item['src']}
        />)
    }

    return (
      <div className="Work">
        <div className="flex-grid">

          <div className="col">
            {items.slice(0, 3)}
          </div>

          <div className="col">
            {items.slice(3, 6)}
          </div>

          <div className="col">
            {items.slice(6, 9)}
          </div>

        </div>
      </div>
  )}
  };

export default Work;