// This is the hero component in
// the React application

import React from 'react';
import './Work.css';
import SpringModal from '../helper/modal.js';

export default function WorkItemDetail(props) {

            return(
                <>
                <SpringModal 
                    {...props}>
                </SpringModal>
                </>
            )
    }
