import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Product.css';
import supplyChainMgmt from './assets/supply-chain-management.png';
import mapIcon from './assets/map.png';
import collabIcon from './assets/brainstorming.png';
import realTimeIcon from './assets/analytics.png';
import webPortal from './assets/video-stream.png';
import riskIcon from './assets/risk.png';
import complianceIcon from './assets/application.png';
import lifecycleIcon from './assets/continuous-improvement.png';

const Product = () => {
  const navigate = useNavigate();
  const handleBookDemo = () => {
    // Navigate to the Contact form
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
  };

  return (
    <div className="product">
      <div className="hero">
        <br></br><br></br><br></br>
        <h1 className="hero-text">Revolutionize Your Fashion Supply Chain with Kimono Global SaaS Platform</h1>
        <p className="hero-description">Track ESG Metrics, Boost Transparency, and Unleash the Power of Sustainable Fashion</p>
        <br></br><br></br>
      </div>
      <div className="reasons-to-believe">
        <h2>Industry Experts in Fashion and Data with Proven Solutions</h2>
        <p>
        Transparency is the key solution for advancing Fashion supply chains' ESG+ authenticity and collective ownership. With Kimono Global, you can easily capture sustainability metrics within the fashion textile supply chain using digital tools, eliminating the need for excessive hiring of sustainability analysts and professionals. <br></br><br></br>Our platform provides comprehensive industry-wide data, tackles regulatory language challenges, and ensures accountability by uncovering human rights risks hidden within lower tiers of the supply chain.
        </p>
        <button className="hero-button" onClick={handleBookDemo}>
            Book a Demo
        </button>
      </div>
      <div className="feature-grid">
        <div className="feature-block">
          <img src={supplyChainMgmt} alt="Feature Icon" className="feature-icon" />
          {/* <a href="https://www.flaticon.com/free-icons/supply-chain-management" title="supply chain management icons">Supply chain management icons created by Parzival’ 1997 - Flaticon</a> */}
          <h3 className="feature-title">Streamlined Supply Chain Tracking</h3>
          <p className="feature-description">
            Kimono Global allows you to accurately track the origin of fashion textile products and practices throughout the supply chain, ensuring compliance with import requirements and labor practices. Say goodbye to the opacity of the bill of materials and mitigate the risk of paying hefty fees at customs for unverifiable product origins.
          </p>
        </div>
        <div className="feature-block">
          <img src={mapIcon} alt="Feature Icon" className="feature-icon" />
          {/* <a href="https://www.flaticon.com/free-icons/search-location" title="search location icons">Search location icons created by Smashicons - Flaticon</a> */}
          <h3 className="feature-title">Enhanced Visibility and Accountability</h3>
          <p className="feature-description">
            Gain unparalleled visibility into your fashion textile supply chain, even beyond the first tier. Kimono Global incentivizes lower-tier subcontractors to adhere to ESG practices, providing robust data to track subcontracting and ensure compliance throughout the audit cycle. Mitigate risks, ensure transparency, and boost your company's confidence in reducing CO2 emissions and meeting ESG commitments.
          </p>
        </div>
        <div className="feature-block">
          <img src={collabIcon} alt="Feature Icon" className="feature-icon" />
          {/* <a href="https://www.flaticon.com/free-icons/knowledge" title="knowledge icons">Knowledge icons created by Freepik - Flaticon</a> */}
          <h3 className="feature-title">Collaborative Knowledge Sharing</h3>
          <p className="feature-description">
            Kimono Global fosters collaboration within the fashion industry by enabling collective ownership of ESG practices. Share knowledge, best practices, and resources among big retailers, brands, and SMEs. Overcome talent shortages, recruit sustainability professionals, and gain board-level buy-in for effective ESG implementation.
          </p>
        </div>
        <div className="feature-block">
        <img src={realTimeIcon} alt="Feature Icon" className="feature-icon" />
        {/* <a href="https://www.flaticon.com/free-icons/statistics" title="statistics icons">Statistics icons created by Freepik - Flaticon</a> */}
        <h3 className="feature-title">Real-time Data Analytics</h3>
        <p className="feature-description">
          Access real-time data analytics and actionable insights to make informed decisions and drive sustainability improvements in your fashion supply chain. Identify areas of improvement, track progress, and optimize operations for maximum efficiency.
        </p>
      </div>

      <div className="feature-block">
        <img src={webPortal} alt="Feature Icon" className="feature-icon" />
        {/* <a href="https://www.flaticon.com/free-icons/monitor" title="monitor icons">Monitor icons created by prettycons - Flaticon</a> */}
        <h3 className="feature-title">Supplier Collaboration Portal</h3>
        <p className="feature-description">
          Engage and collaborate with suppliers through a dedicated portal. Share requirements, communicate effectively, and foster stronger relationships with your suppliers. Streamline communication channels and enhance transparency in the supplier ecosystem.
        </p>
      </div>

      <div className="feature-block">
        <img src={riskIcon} alt="Feature Icon" className="feature-icon" />
        {/* <a href="https://www.flaticon.com/free-icons/mitigation" title="mitigation icons">Mitigation icons created by lutfix - Flaticon</a> */}
        <h3 className="feature-title">Risk Assessment and Mitigation</h3>
        <p className="feature-description">
          Identify and mitigate supply chain risks, including environmental, social, and governance (ESG) risks, through advanced risk assessment tools. Proactively address potential disruptions and ensure compliance with sustainability standards and regulations.
        </p>
      </div>

      <div className="feature-block">
        <img src={lifecycleIcon} alt="Feature Icon" className="feature-icon" />
        {/* <a href="https://www.flaticon.com/free-icons/continuous-improvement" title="continuous-improvement icons">Continuous-improvement icons created by Freepik - Flaticon</a> */}
        <h3 className="feature-title">Lifecycle Analysis</h3>
        <p className="feature-description">
          Perform comprehensive lifecycle analysis of your fashion products to evaluate their environmental impact. Measure carbon footprint, water usage, and other sustainability metrics throughout the entire product lifecycle, from sourcing to disposal.
        </p>
      </div>

      <div className="feature-block">
        <img src={complianceIcon} alt="Feature Icon" className="feature-icon" />
        {/* <a href="https://www.flaticon.com/free-icons/enforcement" title="enforcement icons">Enforcement icons created by Vectors Tank - Flaticon</a> */}
        <h3 className="feature-title">Reporting and Compliance</h3>
        <p className="feature-description">
          Generate customized reports and automate compliance documentation for regulatory requirements and certifications. Stay up to date with sustainability reporting frameworks and demonstrate your commitment to sustainable practices.
        </p>
      </div>

      </div>
    </div>
  );
};

export default Product;
